console.log('main.es6.js...');
// A $( document ).ready() block.
$(document).ready(function () {
    console.log("ready!");

    // util to keep responnsiveness withh js if needed
    var viewportWidth = $(window).width();
    $(window).resize(function () {
        viewportWidth = $(window).width();
    });

    // set show cookie
    var cookie = setTimeout(function () {
        const firstVisit = getCookie('jig-first-visit');
        console.log('First Visit: ', firstVisit);
        if (firstVisit == 1) {
            $('.cookie').addClass('show');
            $('.cookie .js--cookie').on('click', function (e) {
                e.preventDefault();
                $('.cookie').removeClass('show');
                setCookie('jig-first-visit', 0, 1);
                // todo  change cookie-agree yes or no values depending on clicks....
                setCookie('jig-cookie-agree', 0, 1);
            });
        }
    }, 750);

    // toggle accordion of BOOK TOC
    $('.book--toc-item').on('click', function (e) {
        console.log('accordion it');
        // e.preventDefault();
        if (!$(this).hasClass('active')) {
            $('.active').removeClass('active');
            $(this).toggleClass('active');
        }
    });

    // create slideshow of FEATURED IMAGE AND CAPTION
    if ($('body').hasClass('book')) {
        var chapterTitle = $('.level-1 h2').html();
        var chapterCaption = '';
        var chapterFootnotes = '';
        var slide = '';
        var slideIDs = [];
        $('.layout--book').each(function (i, d) {
            var extraClass = '';
            if ($('.layout--book-section', this).hasClass('level-1')) extraClass = 'level-1';
            if ($('.book-section--featured', this).length) {
                var unique = $('.layout--book-section', this).attr('id');

                slideIDs.push(unique);
                console.log(slideIDs);
                slide += '<div class="book-slide ' + extraClass + '">';
                slide += $('.book-section--featured', this).html();
                slide += '</div>';
                chapterCaption += '<div class="caption-slide ' + extraClass + '">';
                if ($('.book-section--featured-caption', this).length) {
                    chapterCaption += $('.book-section--featured-caption', this).html();
                }
                chapterCaption += '</div>';
            }
        });
        var offsetCaption = slideIDs.length;

        // create navigation for SUBCHAPTERS
        var sectionToc = '<nav class="nav--chapter panel-util"><h4>Subchapters</h4><ul>';
        $('.layout--book-section').each(function (i, d) {
            if ($(this).hasClass('level-1')) {
                var href = $(this).attr('id');
                var title = $('h2', this).html();
                sectionToc += '<li class="level-1"><a class="" href="#' + href + '">' + title + '</a></li>';
            }
            // create ids for all h3 elements
            else if ($(this).hasClass('level-2')) {
                $('.book-section--content h3.level-2', this).each(function (i, d) {
                    var title = $(this).text();
                    console.log(title);
                    var href = title.replace(/\s+/g, '-').replace(/\./g, '-').replace(/\:/g, '-').toLowerCase();
                    $(this).attr('id', href);
                    sectionToc += '<li class="level-2"><a class="" href="#' + href + '">' + $(this).html() + '</a></li>';
                });
            }
			// create ids for all h3 elements
			else if ($(this).hasClass('level-3')) {
				$('.book-section--content h4.level-3', this).each(function (i, d) {
					var title = $(this).text();
					console.log(title);
					var href = title.replace(/\s+/g, '-').replace(/\./g, '-').replace(/\:/g, '-').toLowerCase();
					$(this).attr('id', href);
					sectionToc += '<li class="level-3"><a class="" href="#' + href + '">' + $(this).html() + '</a></li>';
				});
			}
        });
        sectionToc += '</ul></nav>';

        // create slides for FOOTNOTES
        $('#section-array-endnotes li').each(function (i, d) {
            // create html
            var footIndex = $(this).index();
            footIndex++;
            chapterFootnotes += '<div id="footSlide' + footIndex + '" class="caption-slide footnote-slide">';
            chapterFootnotes += '<h4>' + footIndex + '</h4>';
            chapterFootnotes += '<aside>' + $(this).html() + '</aside>';
            chapterFootnotes += '</div>';
        });

        // create slide for DOWNLOAD
        var download = '<div class="dashboard--download panel-util"><h4>Download</h4><p>Coming soon!</p></div>';

        // create slide for SHARE
        var share = '<div class="dashboard--share panel-util"><h4>Share</h4><br/><a class="button clipboard">Copy link to share</a><p>This copies to clipboard.</p></div>';

        // add ALL to slick slider
        slide += sectionToc;
        slide += download;
        slide += share;
        chapterCaption += chapterFootnotes;

        // push new ids to array
        slideIDs.push('overlay--chapter-toc');
        slideIDs.push('overlay--download');
        slideIDs.push('overlay--share');

        console.log(slideIDs);

        // add FEATURED IMAGE to PANEL MAIN
        $('.panel--main').html(slide);
        // add FEATURED CAPTION to PANEL SEC
        $('.panel--sec').html(chapterCaption);
        // add CHHAPTER TITLE to PANEL CHAPTER
        $('.panel--chapter').html(chapterTitle);
        // add SUBCHHAPTER, DOWNLOAD, SHARE to OVERLAY
        $('.overlay--inside').append(sectionToc, download, share);

        // init FEATURED IMAGE slideshow
        $('.panel--main').slick({
            dots: false,
            arrows: false,
            slidesToShow: 1,
            speed: 50,
            fade: true,
            cssEase: 'linear',
            swipe: false,
            responsive: [
                {
                    breakpoint: 959,
                    settings: "unslick"
                }
            ]
        });

        // init FEATURED CAPTION slideshow
        $('.panel--sec').slick({
            dots: false,
            arrows: false,
            slidesToShow: 1,
            speed: 50,
            fade: true,
            cssEase: 'linear',
            swipe: false,
            responsive: [
                {
                    breakpoint: 959,
                    settings: "unslick"
                }
            ]
        });

        // toggle handler for BOOK OVERLAY/SLIDE NAVIGATION
        $('.js--overlay').on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('active')) {
                $('.js--overlay.active').removeClass('active');
            } else {
                $('.js--overlay.active').removeClass('active');
                $(this).toggleClass('active');
            }
            var what = $(this).attr('data-overlay');
            console.log(what);
            if (viewportWidth > 959) {
                var pos = $.inArray(what, slideIDs);
                console.log(pos, slideIDs[pos]);
                // Get the current slide
                var currentSlide = $('.panel--main').slick('slickCurrentSlide');
                if (currentSlide == pos) {
                    // refresh waypoints
                    $('.panel--main').slick('slickGoTo', activeSlide);
                    $('.panel--sec').slick('slickGoTo', activeSlide);
                } else $('.panel--main').slick('slickGoTo', pos);
            } else if ($('body').hasClass(what)) {
                $('body').toggleClass('overlay--open ' + what);
            } else if ($('body').hasClass('overlay--open')) {
                $('body').removeClass('mini-overlay--open');
                $('body').removeClass('overlay--share overlay--chapter-toc overlay--download overlay--menu overlay--share');
                $('body').toggleClass(what);
            } else {
                $('body').removeClass('mini-overlay--open');
                $('body').toggleClass('overlay--open ' + what);
            }

            switch (what) {
                case 'overlay--share':
                    var $temp = $("<input>");
                    var $url = $(location).attr('href');
                    $(".clipboard ~ p").text($url);
                    $('.clipboard').on('click', function () {
                        $("body").append($temp);
                        $temp.val($url).select();
                        document.execCommand("copy");
                        $temp.remove();
                        $(".clipboard ~ p").text("URL copied!");
                    })
                    break;
            }
        });
    }

    // toggle handler for MAIN MENU
    $('.js--menu-toggle').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('active')) {
            $('.js--menu-toggle.active').removeClass('active');
        } else {
            $('.js--menu-toggle.active, .js--overlay.active').removeClass('active');
            $(this).toggleClass('active');
        }
        var what = $(this).attr('data-overlay');
        console.log(what);
        if ($('body').hasClass(what)) {
            $('body').toggleClass('overlay--open ' + what);
        } else if ($('body').hasClass('overlay--open')) {
            $('body').removeClass('mini-overlay--open');
            $('body').removeClass('overlay--share mini-overlay--open overlay--chapter-toc overlay--download overlay--menu overlay--share');
            $('body').toggleClass(what);
        } else {
            $('body').removeClass('mini-overlay--open');
            $('body').toggleClass('overlay--open ' + what);
        }
    });

    // remember actual active slide
    var activeSlide = 0;

    // initialize waypoints on book section div
    var waypoints = $('.layout--book-section').waypoint({
        handler: function (direction) {
            console.log('Hit midpoint of my context', direction, this.element.id);
            var pos = $.inArray(this.element.id, slideIDs);
            console.log(pos, slideIDs[pos]);
            if (pos !== -1) {
                switch (direction) {
                    case 'up':
                        pos--;
                        break;
                }
                console.log('about to slickGoTo');
                $('.panel--main').slick('slickGoTo', pos);
                $('.panel--sec').slick('slickGoTo', pos);
                activeSlide = pos;
            }
        },
        offset: '50%'
    });

    // GO ON FROM HERE
    // U HAVE TO CATCH THE footnotes
    // SAVE THE ID FROM TEXT OR ATTR ID
    // ADD THE APPROPRIATE OFFSET OF THE OTHER CAPTION slides
    // DO IT !!!
    console.log(offsetCaption);
    var waypointsFootnotes = $('a[title="footnote"], .book-section--content sup').waypoint({
        handler: function (direction) {
            var temp = this.element;
            var pos = parseInt($(temp).text());
            pos = pos + offsetCaption - 1;
            if (pos !== -1) {
                switch (direction) {
                    case 'up':
                        // $('.panel--sec').slick('slickGoTo', activeSlide);
                        break;
                }
                console.log('about to slickGoTo');
                $('.panel--sec').slick('slickGoTo', pos);
            }
        },
        offset: '50%'
    });

    // click handler for FOOTNOTES
    $('a[title="footnote"], .book-section--content sup').on('click', function (e) {
        e.preventDefault();
        var pos = parseInt($(this).text());
        pos = pos + offsetCaption - 1;
        if (viewportWidth > 959) {
            if (pos !== -1) {
                $('.panel--sec').slick('slickGoTo', pos);
            }
        } else {
            $('body').addClass('mini-overlay--open');
            $('.mini-overlay .overlay--inside').html('<div class="footnote-slide">' + $('#footSlide' + pos).html() + '</div>');
            // click handler for CLOSING FOOTNOTES
            $('.mini-overlay .mu-cancel').on('click', function () {
                $('body').removeClass('mini-overlay--open');
                // $('.mini-overlay .overlay--inside').html('');
            });
        }
    });
    //3d book animations on front stuff
    $(".book").mouseenter(function () {
        $(this).parent(".book-wrap").addClass("rotate");
    });

    $(".book").mouseleave(function () {
        $(this).parent(".book-wrap").removeClass("rotate");
    });

    $(".book").click(function () {
        $(this).parent(".book-wrap").addClass("flip");
    });

    $(".book-back").click(function () {
        $(this).parent(".book-wrap").removeClass("flip");
    });

});

//help functio to get coockie
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

//helper function to set Coockies
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}